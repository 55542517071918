const config = {
  configEndpoints: {
    baseEndpoint: "https://api.hub.affinity.io/portal",
  },
  configSentry: {
    enabled: true,
    dsn: "https://9d44573e6f4d46a9ade35bdf0fb91d9d@o240624.ingest.sentry.io/6744348",
  },
};
export default config;
