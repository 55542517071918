import React, { useEffect } from "react";
import { SsoLogout } from "../../auth/components/SsoLogout";

function Logout() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return <SsoLogout clientId={window.authConfig?.adminPortalClientId} />;
}

export default Logout;
