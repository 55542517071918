const config = {
  configEndpoints: {
    baseEndpoint: "http://localhost:9000/portal",
  },
  configSentry: {
    enabled: false,
  },
};

export default config;
