import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  AuthCallback,
  SilentAuthCallback,
} from "./modules/auth/components/AuthCallback";
import { setupIdentity } from "./modules/auth/api/identityAPI";
import { PrivateRoute } from "./modules/auth/components/PrivateRoute";
import AccountsContainer from "./modules/account/components/AccountsContainer";
import Page404 from "./modules/_core/components/Page404";
import ScrollToTop from "./modules/_core/components/ScrollToTop";
import Alerts from "./modules/_core/components/Alerts";
import Store from "./modules/_core/store/Store";
import UILoader from "./modules/_core/components/ui/UILoader";
import Logout from "./modules/_core/components/Logout";
import NoUser from "./modules/_core/components/NoUser";
import ThemeProvider from "./modules/_core/providers/ThemeProvider";
import { useHubUserExist } from "./modules/users/hooks/auth";
import UsersContainer from "./modules/users/components/UsersContainer";
import AddUserContainer from "./modules/users/components/AddUserContainer";
import { getAuthConfig } from "./modules/users/api/auth";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      retry: 1,
    },
  },
});

function App() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const authConfig = await getAuthConfig();
        window.authConfig = authConfig;

        setupIdentity(
          authConfig.adminPortalClientId,
          authConfig.tokenIssuerUrl
        );
        setIsReady(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  if (!isReady) return <UILoader overlay />;
  return (
    <QueryClientProvider client={queryClient}>
      <Store>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          toggleButtonProps={{
            style: { width: 20, height: 30, opacity: 0.4 },
          }}
        />
      </Store>
    </QueryClientProvider>
  );
}

function Routes() {
  return (
    <Router>
      <Alerts />
      <ScrollToTop />
      <Redirector />
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/no-user">
          <NoUser />
        </Route>
        <Route path="/oauth_callback">
          <AuthCallback />
        </Route>
        <Route path="/silent_oauth_callback">
          <SilentAuthCallback />
        </Route>
        <PrivateRoute path="/users" exact>
          <UsersContainer />
        </PrivateRoute>
        <PrivateRoute path="/users/new" exact>
          <AddUserContainer />
        </PrivateRoute>
        <PrivateRoute path="/" exact>
          <AccountsContainer />
        </PrivateRoute>
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
}

const Redirector = () => {
  const { data: doesHubUserExist, isFetchedAfterMount } = useHubUserExist();
  if (isFetchedAfterMount && !doesHubUserExist) {
    return <Redirect to="/no-user" />;
  }
  return null;
};

export default App;
