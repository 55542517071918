import { UserManager, UserManagerSettings } from "oidc-client-ts";
import { IdentityUser } from "./types";

let mgr: UserManager;

export const COGNITO_TOKEN_ISSUER_URL =
  "https://cognito-idp.eu-west-2.amazonaws.com";

export function setupIdentity(
  clientId: UserManagerSettings["client_id"],
  tokenIssuerUrl: UserManagerSettings["authority"]
) {
  // Without Advanced Security turned on (costs around $4k/month), Cognito can neither adjust Access Token scopes nor claims.
  // Hence, using ID token instead, to avoid excessive costs.
  // See https://auth0.com/blog/id-token-access-token-what-is-the-difference
  // See https://aws.amazon.com/blogs/security/how-to-customize-access-tokens-in-amazon-cognito-user-pools
  // Since Cognito pre-token-generation Lambda does not provide requested token scopes by clients,
  // The pre-token-generation Lambda cannot copy the scopes to the ID token.
  // Instead, Cognito Groups replace scopes, because groups are available in the pre-token-generation Lambda request.
  // The groups are copied to the ID token's scope so that the Hub backend can authorise users.
  // That is why there is no `HUB_FE_API` scope provided for Cognito.
  // TODO Remove this once Affinity SSO tenants are migrated to Cognito
  const adminPortalApiScopes = tokenIssuerUrl?.startsWith(
    COGNITO_TOKEN_ISSUER_URL
  )
    ? ""
    : "IdentityServerApi HUB_PORTAL_API"; // The `IdentityServerApi` scope is necessary for Affinity SSO impersonation

  const config: UserManagerSettings = {
    client_id: clientId,
    authority: tokenIssuerUrl,
    redirect_uri: window.location.origin + "/oauth_callback",
    silent_redirect_uri: window.location.origin + "/silent_oauth_callback",
    post_logout_redirect_uri: window.location.origin,
    automaticSilentRenew: true,
    validateSubOnSilentRenew: true,
    response_type: "code",
    loadUserInfo: true,
    scope: `openid profile email ${adminPortalApiScopes}`,
  };

  mgr = new UserManager(config);
  return mgr;
}

export function logout(clientId: string) {
  return mgr?.signoutRedirect({
    extraQueryParams: {
      client_id: clientId,
      logout_uri: window.location.origin,
    },
  });
}

export function login() {
  return mgr?.signinRedirect();
}

export async function loginCallback() {
  try {
    const token = await mgr.signinRedirectCallback();
    return token;
  } catch (error) {
    console.log("Login callback error:", error);
  }
}

export function silentLoginCallback() {
  return mgr
    .signinCallback()
    .then(function () {})
    .catch(function (e: Error) {
      console.error(e);
    });
}

export function getUser(): Promise<IdentityUser | null> {
  return mgr?.getUser();
}

export function removeUser() {
  return mgr?.removeUser();
}

export function renewTokens() {
  return mgr?.startSilentRenew();
}
