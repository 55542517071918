import { getUser } from "../../auth/api/identityAPI";
import api from "../../_core/api";
import { AuthConfig } from "./types";

export const getIdentityUser = async () => {
  return getUser();
};

export const doesHubUserExist = async () => {
  try {
    const response = await api({
      method: "GET",
      url: "/users/my/exists",
    });

    return response.status === 204;
  } catch (error) {
    return false;
  }
};

export const getAuthConfig = async () => {
  const { data }: { data: AuthConfig } = await api({
    method: "GET",
    url: "/auth-config",
    is_hub: true,
    skip_bearer_injection: true,
    skip_token_refresh: true,
  });

  if (!data?.tokenIssuerUrl || !data?.adminPortalClientId) {
    throw Error(
      `Missing some of the mandatory attributes in the returned data: ${data}`
    );
  }

  return data;
};
